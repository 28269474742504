import React from "react";
import { Container, Screen } from "./Landing";
import { Heading } from "../components/Heading";
import styled from "styled-components";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

export const StyledLogo = styled.img`
	width: 300px;
	padding-top: 50px;
	@media (min-width: 767px) {
		width: 400px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

//Text defined here cause this is p tag here instead of span
export const Text = styled.p`
	font-size: 1.6rem;
	color: white;
	margin-top: 25px;
	line-height: 40px;
`;

const TransparencyManifesto = () => {
	const navigate = useNavigate();

	return (
		<Screen>
			<Container flex={1} ai={"center"} style={{ backgroundColor: "var(--primary)" }}>
				<StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
				<div style={{ width: "60%", margin: " 50px auto" }}>
					<Heading>HYPEE NFT Mint Transparency Manifesto</Heading>
					<Text>
						The goal of these measures is to ensure that the collection maintains its integrity and allow you to prove that the ordered
						collection remains unadulterated.
					</Text>
					<Text>
						<ul>
							<li>All team members will participate in the allowlist and minting process of the NFT along with community members</li>
							<li>There will be no “hidden period” once minting begins. All NFTs will be revealed at the time of the mint.</li>
							<li>
								One (1) hour after presale begins, 200 NFTs will be minted in a single transaction. All tokens will be sent to OVRT
								community wallet:{" "}
								<span style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>0xC59a0993763436d864A5078412F2fA175303c6aa</span>
								<ul style={{ marginLeft: "50px", fontSize: "1.4rem" }}>
									<li>28 NFTs will be reserved for core team and contributors of the project:</li>
									<li>30 NFTs will be reserved for guest artists that have collaborated for this project:</li>
									<li>142 NFTs will stay in community wallet for marketing purposes</li>
								</ul>
							</li>
							<li>HYPEE artwork will be inspected for quality purposes by only the core team members</li>
							<li>Metadata of attributes and rarities will be randomized</li>
						</ul>
					</Text>
				</div>
				<Button onClick={() => navigate("/")}>Go Back</Button>
				<Footer hideTransparencyManifesto />
			</Container>
		</Screen>
	);
};

export default TransparencyManifesto;
