import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "../components/Button";
import Mint from "../components/Mint";
import { connect } from "../redux/blockchain/blockchainActions";
import { SmallText, Text } from "../components/Heading";
import ComingSoon from "../components/ComingSoon";
import Web3 from "web3";
import ErrorMessage from "../components/ErrorMessage";

const MESSAGES = {
	TOTAL_SUPPLY_LEFT: (totalLeft, totalSet) => `${totalLeft} / ${totalSet} HYPEEs minted so far`,
	MINT_PRICE_MESSAGE: (weiAmount) => `Mint Price: ${Web3.utils.fromWei(weiAmount, "ether")} ETH per HYPEE`,
	SELECT_MINT_NUMBER: () => `Please select the number of HYPEEs you would like to mint`,
	USER_NOT_WHITELISTED: () => `This wallet address does not qualify for presale`,
	MOBILE_VIEW: () => `For best experience, mint from a computer`,
};

const Action = ({
	showConnectButton,
	showMintingCounter,
	contractLoadError,
	accountConnectError,
	showSupplyMessage,
	errorMessage,
	presaleActive,
	publicSaleActive,
	nonWhitelistedUser,
}) => {
	const state = useSelector((state) => state);

	const dispatch = useDispatch();

	const handleConnectClick = (e) => {
		e.preventDefault();
		dispatch(connect());
	};

	const DisplayMsg = ({ totalSupplyLeft, totalSupplySet, mintingPrice }) => {
		return (
			<Text>
				{MESSAGES.TOTAL_SUPPLY_LEFT(totalSupplyLeft, totalSupplySet)}
				<br />
				{MESSAGES.MINT_PRICE_MESSAGE(mintingPrice)}
				<br />
				<br />
				{MESSAGES.SELECT_MINT_NUMBER()}
			</Text>
		);
	};

	const displayArray = [];

	//Welcome Message, shown always
	displayArray.push(
		<>
			<ComingSoon presaleActive={presaleActive} publicSaleActive={publicSaleActive} counterVisible={showMintingCounter} />
			<br />
		</>
	);

	//If Minting counter is shown, show the minting happening, price, quantity message
	if (showMintingCounter) {
		// displayArray.push(
		// 	<DisplayMsg
		// 		totalSupplyLeft={state.contractData.totalSupplyLeft}
		// 		totalSupplySet={state.contractData.maxSupplySet}
		// 		mintingPrice={publicSaleActive ? state.contractData.publicSaleCostSet : state.contractData.presaleCostSet}
		// 	/>
		// );

		displayArray.push(
			<>
				{publicSaleActive ? <Mint /> : <Mint presaleActive />}
				<br />
			</>
		);
	}

	//If user not whitelisted, show the error msg
	if (nonWhitelistedUser) {
		displayArray.push(<Text>{MESSAGES.USER_NOT_WHITELISTED()}</Text>);
	}

	//Show the connect button
	if (showConnectButton) {
		displayArray.push(
			<>
				<Button
					onClick={handleConnectClick}
					disabled={state.blockchain.loading}
					style={{ cursor: !state.blockchain.loading ? "pointer" : "wait" }}
				>
					{state.blockchain.loading ? <div style={{ margin: "0px 50px", width: "0", height: "0" }} className='loader' /> : "Connect"}
				</Button>
				<SmallText className='showMobile' style={{ marginTop: "25px" }}>
					{MESSAGES.MOBILE_VIEW()}
				</SmallText>
			</>
		);
	}

	//Show the error message if errorMessage is true
	if (errorMessage) {
		displayArray.push(<ErrorMessage />);
	}

	return <div style={{ textAlign: "center" }}>{displayArray}</div>;
};

export default Action;
