// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";
import CONFIG from '../../config/config.json';
import abi from '../../config/abi.json';
import { isWhitelisted } from '../../helper';

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};




export const connect = () => {
  return async (dispatch) => {
    //Connection request being made to change the status to LOADING
    dispatch(connectRequest());

    //web3 checking if ethereum and metamask exists in the window
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;


    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        //Request accounts
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        //Request networkId to match the wallet with our contract currency
        const networkId = await ethereum.request({
          method: "net_version",
        });

        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );


          const methods = SmartContractObj.methods;

          const totalSupply = await methods.totalSupply().call();
          const isPresaleActive = await methods.presaleActive().call();
          const isPublicSaleActive = await methods.publicSaleActive().call();
          const presaleCost = await methods.presaleCost().call();
          const publicSaleCost = await methods.publicCost().call();
          const symbol = await methods.symbol().call();
          const contractLoadedSuccess= true;
          const isActive = (!await methods.paused().call());
          const maxSupply = await methods.maxSupply().call();


          //Is the account whitelisted?
          const  registered  = await isWhitelisted(accounts[0]);
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
              isWhitelisted: registered,
              contractData: {
                totalSupply,
                isPresaleActive,
                isPublicSaleActive,
                presaleCost,
                publicSaleCost,
                symbol,
                contractLoadedSuccess,
                isActive,
                maxSupply
            }
            })
          );

          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        console.log(err);
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
