import React from "react";
import { Text } from "../components/Heading";

const ErrorMessage = () => {
	return (
		<Text style={{ textAlign: "center", width: "60%" }}>
			Unable to connect to Smart Contract. Please check your connection and try again. <br />
			If issue persist, please contact 🔧#-support Channel in the Discord Server
		</Text>
	);
};

export default ErrorMessage;
